import type { PropsWithChildren } from "react"

import svgLogo from "common/assets/logo.svg"

export const ErrorLayout = ({ children }: PropsWithChildren<{}>) => (
  <div className="mx-auto flex max-w-xl flex-col items-center">
    <img alt="The Conversation" className="px-2 py-8" src={svgLogo} width="483" height="61" />
    {children}
  </div>
)
