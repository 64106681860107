import {
  createContext,
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  useMemo,
  useState,
} from "react"
import { NotificationProps } from "common/components/NotificationBar/types"

interface NotificationContextValue {
  notifications: NotificationProps[]
  setNotifications: Dispatch<SetStateAction<NotificationProps[]>>
}

export const Context = createContext<NotificationContextValue>({
  notifications: [],
  setNotifications: () => {},
})

export const NotificationsProvider = ({ children }: PropsWithChildren) => {
  const [notifications, setNotifications] = useState<NotificationProps[]>([])

  const notificationsContextValue = useMemo(
    () => ({ notifications, setNotifications }),
    [notifications, setNotifications],
  )

  return <Context.Provider value={notificationsContextValue}>{children}</Context.Provider>
}
