import Script from "next/script"
import { useEffect } from "react"
import getConfig from "next/config"
import { NoScriptTag } from "common/components/NoScriptTag"
import { useViewer } from "common/hooks/data/useViewer"
import {
  hasJustSignedInCookie,
  deleteSignedInCookie,
} from "common/components/GoogleTagManager/sessionCookie"
import { RoleEnum } from "common/types/graphql"
import { useRegionCode } from "modules/i18n/hooks/useRegionCode"
import { usePageProps } from "common/hooks/data/usePageProps"
import { ViewPageProps } from "pages/view/index.page"

/*
  See also: https://github.com/conversation/docs/tree/main/product/google-analytics
*/

/**
 * Check if Google Tag Manager is enabled on the environment
 */
export const isEnvEnabled = (): boolean =>
  getConfig().publicRuntimeConfig.GOOGLE_TAG_MANAGER_ENABLED == "true"

/**
 * Pushes data to the Google Tag Manager dataLayer
 */
export const push = (data: object) => {
  const dataLayer = window.dataLayer || []
  dataLayer.push(data)
}

/**
 * Google Tag Manager `head` component, allowing us to add GTM to the entire application.
 * This component is based on NextJS sample Google Tag Manager application:
 * https://github.com/vercel/next.js/tree/66a0083235019314e3a9d3637507eb7ac330141d/examples/with-google-tag-manager
 */
export const GoogleTagManager = () => {
  const { viewer } = useViewer()
  const { pageType } = usePageProps<ViewPageProps>()
  const regionCode = useRegionCode()

  // Note on Google Tag Manager custom dimensions:
  // => `RegionCode`: we are using the current region code
  // => `CommissioningRegion`: we are using the same value as `RegionCode` while PageBuilder only handle pages. This will change
  // when we start dealing with content
  // => `ContentType`: while we are only dealing with pages, we don't need to set a `ContentType`. This custom dimension value
  // is empty for pages.
  // Push custom dimensions to GTM dataLayer
  useEffect(() => {
    push({
      CommissioningRegion: regionCode,
      RegionCode: regionCode,
      UserType: viewer?.role || RoleEnum.Reader,
    })
  }, [viewer, regionCode])

  // Push user details to GTM dataLayer
  useEffect(() => {
    if (viewer === undefined) {
      return
    }

    const userId = viewer.user?.id || ""

    push({
      event: "user_details",
      user_id: `${userId}`,
    })

    if (hasJustSignedInCookie()) {
      push({
        event: "login",
        user_id: `${userId}`,
      })

      deleteSignedInCookie()
    }
  }, [viewer])

  // Push the page view event to GTM dataLayer
  useEffect(() => {
    if (pageType !== undefined) {
      push({ event: "page_view", page_type: pageType })
    }
  }, [pageType])

  if (!isEnvEnabled()) {
    return null
  }

  const CONTAINER_ID = getConfig().publicRuntimeConfig.GOOGLE_TAG_MANAGER_CONTAINER_ID

  return (
    <>
      <Script
        id="gtag-base"
        strategy="afterInteractive"
        data-testid="google-tag-manager-head"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${CONTAINER_ID}');
          `,
        }}
      />
    </>
  )
}

/**
 * Google Tag Manager `noscript` implementation, allowing us to add GTM when JS is disabled.
 * This component is based on NextJS sample Google Tag Manager application:
 * https://github.com/vercel/next.js/tree/66a0083235019314e3a9d3637507eb7ac330141d/examples/with-google-tag-manager
 */
const NoScript = () => {
  if (!isEnvEnabled()) {
    return null
  }

  const CONTAINER_ID = getConfig().publicRuntimeConfig.GOOGLE_TAG_MANAGER_CONTAINER_ID

  return (
    <NoScriptTag>
      <iframe
        title="gtm-noscript"
        src={`https://www.googletagmanager.com/ns.html?id=${CONTAINER_ID}`}
        height="0"
        width="0"
        style={{ display: "none", visibility: "hidden" }}
      />
    </NoScriptTag>
  )
}
NoScript.displayName = "GoogleTagManager.NoScript"
GoogleTagManager.NoScript = NoScript
