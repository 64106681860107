import Script from "next/script"
import getConfig from "next/config"
import { useEffect } from "react"
import { scriptType } from "common/components/Didomi"
import { useRegionConfig } from "modules/i18n/hooks/useRegionConfig"

const SMART_OCTO_REAL_TIME_API_TOKEN = "zu0fth1pwaw4xbz53mh0yl3ik80ndct1"
const ORIGIN_URL = "https://theconversation.com"

/**
 * SmartOcto real time-API token snippet and tentacles.js component.
 *
 * This component triggers the `window.postMessage("activateTentacles")` event necessary
 * to activate the SmartOcto tentacles.js SPA script setup.
 *
 * @see https://knowledgebase.smartocto.com/knowledge/smartocto-tentacles-script-setup
 */
export const SmartOcto = () => {
  const isEnvEnabled = getConfig().publicRuntimeConfig.SMART_OCTO_ENABLED == "true"
  const regionConfiguration = useRegionConfig()

  useEffect(() => {
    if (!isEnvEnabled) {
      return
    }

    /**
     * We are specifying the origin URL to ensure that the message is only sent by us to SmartOcto
     * and not by any other website.
     * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage#security_concerns
     */
    window.postMessage("activateTentacles", ORIGIN_URL)
  }, [isEnvEnabled])

  if (!isEnvEnabled) {
    return null
  }

  return (
    <>
      <Script
        data-testid="smart-octo-real-time-api-token-snippet"
        id="smart-octo-real-time-api-token-snippet"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.tentacles = { apiToken: "${SMART_OCTO_REAL_TIME_API_TOKEN}" };
          `,
        }}
      />
      <Script
        data-testid="smart-octo-real-time-js"
        strategy="afterInteractive"
        type={scriptType(regionConfiguration)}
        data-vendor="c:smartocto-GJ7CA7CP"
        src="https://tentacles.smartocto.com/ten/tentacle.js"
      />
    </>
  )
}
