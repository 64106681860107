import Bugsnag from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"
import getConfig from "next/config"

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig()

const apiKey = serverRuntimeConfig.BUGSNAG_API_KEY || publicRuntimeConfig.BUGSNAG_API_KEY

if (apiKey) {
  Bugsnag.start({
    apiKey,
    appVersion: publicRuntimeConfig.GIT_COMMIT,
    releaseStage: publicRuntimeConfig.APP_ENV,
    enabledReleaseStages: ["staging", "production"],
    collectUserIp: false,
    plugins: [new BugsnagPluginReact()],
  })
}

export { Bugsnag }
