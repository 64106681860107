/**
 * Gets the value of a property at the given path for an object.
 *
 * @param object The object to query.
 * @param path The path of the property to get.
 * @param defaultValue The value returned for unresolved properties.
 * @returns The resolved value.
 */
export const get = (object: Record<string, any>, path: string, defaultValue?: any) => {
  // Split path into an array
  // Regex explained: https://regexr.com/58j0k
  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g)

  // Find value by traversing the object
  const result = pathArray && Array.from(pathArray).reduce((o, key) => o && o[key], object)

  return result || defaultValue
}
