import Cookies from "js-cookie"

export const SIGNED_IN_COOKIE_NAME = "tc_signed_in"

/**
 * Checks if a user has just signed in, verifying the presence of the signed in cookie.
 */
export const hasJustSignedInCookie = () => Cookies.get(SIGNED_IN_COOKIE_NAME) === "true"

/**
 * Removes the "signed in" cookie
 */
export const deleteSignedInCookie = () => Cookies.remove(SIGNED_IN_COOKIE_NAME)
