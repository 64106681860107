import Head from "next/head"
import libreBaskervilleNormal400Latin from "common/assets/fonts/libre-baskerville-style-normal-weight-400-latin.woff2"
import montserratNormal700Latin from "common/assets/fonts/montserrat-style-normal-weight-700-latin.woff2"

export const FontsPreload = () => (
  <Head>
    <link rel="preload" href={libreBaskervilleNormal400Latin} as="font" crossOrigin="anonymous" />
    <link rel="preload" href={montserratNormal700Latin} as="font" crossOrigin="anonymous" />
  </Head>
)
