import { useGraphqlQuery } from "./useGraphqlQuery"
import { Query, RoleEnum, Viewer } from "common/types/graphql"
import { gql } from "common/lib/gql"
import { createContext, useCallback, useContext, useMemo } from "react"

export const VIEWER_QUERY = gql`
  query {
    viewer {
      role
      user {
        id
        name
        avatar
        commentNotificationCount
        allow {
          analytics
          newHeader
          moderateComments
        }
        newsFeedCount
        donations {
          lastDonated
          lastRegionDonatedTo
          lastDonationRecurring
          numDonations
          numDonationsThisYear
          totalAmount
          totalAmountThisYear
        }
        emails {
          email
          primary
          newsletterSubscriptions {
            newsletterListId
            subscribed
          }
        }
      }
    }
  }
`

export const TestViewerContext = createContext<Pick<Viewer, "role" | "user"> | undefined>(undefined)

/**
 * Data hook to return the current viewer.
 * This hook also implements helper attributes to determine viewer roles.
 */
export const useViewer = () => {
  const { data } = useGraphqlQuery<Query>(VIEWER_QUERY)
  const testViewer = useContext(TestViewerContext)
  const viewer = testViewer || data?.viewer

  /**
   * Helper function to get a viewer's primary email address.
   */
  const getPrimaryEmailAddress = useCallback(() => {
    return viewer?.user?.emails.find((email) => email.primary)?.email || ""
  }, [viewer?.user?.emails])

  /**
   * Helper function to get a viewer's primary email newsletter subscriptions.
   */
  const getNewsletterSubscriptions = useCallback(() => {
    const user = viewer?.user
    const primaryEmail = user?.emails.find((email) => email.primary)
    const newsletterSubscriptions = primaryEmail?.newsletterSubscriptions?.filter(
      ({ subscribed }) => subscribed,
    )

    return newsletterSubscriptions || []
  }, [viewer?.user])

  return {
    viewer,
    isSignedIn: !!viewer?.user,
    getNewsletterSubscriptions,
    getPrimaryEmailAddress,
    isEditor: viewer?.role === RoleEnum.Editor,
    isAuthor: viewer?.role === RoleEnum.Author,
    isReader: viewer?.role === RoleEnum.Reader,
  }
}
