import { ErrorLayout } from "common/components/ErrorLayout"

export const ClientRenderingError = () => {
  return (
    <ErrorLayout>
      <div className="px-2 pb-8 text-center text-lg">
        Something went wrong (client side rendering error). Our technicians have been notified
        automatically. Please try reloading the page in a couple of minutes, or go back to our{" "}
        <a className="underline" href="https://theconversation.com">
          Homepage
        </a>
        . Apologies for the inconvenience and please feel free to contact{" "}
        <a className="underline" href="mailto:support@theconversation.com">
          support
        </a>
        .
      </div>

      <div className="px-2 pb-8 text-center text-lg">
        Algo no funcionó correctamente (client side rendering error). Nuestros técnicos han recibido
        notificación automáticamente. Intente volver a cargar la página dentro de un par de minutos
        o vuelva a nuestra{" "}
        <a className="underline" href="https://theconversation.com">
          página de inicio
        </a>{" "}
        . Disculpe las molestias. No dude en ponerse en contacto con nuestro departamento de{" "}
        <a className="underline" href="mailto:support@theconversation.com">
          asistencia técnica
        </a>
        .
      </div>

      <div className="px-2 pb-8 text-center text-lg">
        Quelque chose ne s’est pas passé comme prévu (client side rendering error). Nos techniciens
        ont été avisés automatiquement. Essayez de recharger la page dans quelque minutes ou visitez
        notre{" "}
        <a className="underline" href="https://theconversation.com">
          page d’acceuil
        </a>
        . Veuillez nous excuser pour cet inconvenient et n’hesitez pas à contacter notre{" "}
        <a className="underline" href="mailto:support@theconversation.com">
          assistance technique
        </a>
        .
      </div>

      <div className="px-2 pb-8 text-center text-lg">
        Ada yang salah (client side rendering error). Teknisi kami telah diberi tahu secara
        otomatis. Silakan memuat lagi lamannya dalam beberapa menit, atau kembali ke{" "}
        <a className="underline" href="https://theconversation.com">
          Beranda kami
        </a>
        . Mohon maaf atas ketidaknyamanan ini dan silakan hubungi bagian{" "}
        <a className="underline" href="mailto:support@theconversation.com">
          support kami
        </a>
        .
      </div>

      <div className="px-2 pb-8 text-center text-lg">
        Algo deu errado (erro de renderização no lado do cliente). Nossos técnicos foram notificados
        automaticamente. Tente recarregar a página em alguns minutos ou volte a nossa{" "}
        <a className="underline" href="https://theconversation.com">
          Página inicial
        </a>
        . Pedimos desculpas pelo inconveniente e sinta-se à vontade para entrar em contato com o{" "}
        <a className="underline" href="mailto:support@theconversation.com">
          suporte
        </a>
        .
      </div>
    </ErrorLayout>
  )
}
