import { PropsWithChildren } from "react"

/**
 * A thin wrapper around the native HTML `<noscript>` tag, as it is not possible to unit test
 * the native tag directly. React testing library does not render `<noscript />` children, so having a wrapper for
 * it allows us to mock the HTML tag and inspect its children.
 */
export const NoScriptTag = ({ children }: PropsWithChildren) => {
  return <noscript>{children}</noscript>
}
