import { useCallback } from "react"
import pupa from "pupa"
import { get } from "common/lib/get"
import { usePageProps } from "common/hooks/data/usePageProps"
import type { AppProps } from "common/lib/getAppServerSideProps"

export const useTranslations = (scope?: string) => {
  const { translations } = usePageProps<AppProps>()

  return useCallback(
    (id: string, data?: Record<string, any>) => {
      const scopedTranslations = scope ? get(translations, scope, {}) : translations
      const value: string = get(scopedTranslations, id, id)
      return data ? pupa(value, data, { ignoreMissing: true }) : value
    },
    [translations, scope],
  )
}
