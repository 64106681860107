import { useContext } from "react"
import { NotificationBar } from "common/components/NotificationBar"
import { Context } from "common/components/NotificationBar/Context"
import { nanoid } from "nanoid"

export const Container = () => {
  const { notifications } = useContext(Context)

  return (
    <div className="fixed top-0 z-10 w-full" aria-live="assertive" role="alert">
      {notifications.map((notification) => {
        return <NotificationBar key={nanoid()} {...notification} />
      })}
    </div>
  )
}
