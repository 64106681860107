import {
  ExclamationCircleIcon,
  CheckCircleIcon,
  NoSymbolIcon,
  InformationCircleIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid"
import clsx from "classnames"
import { useTranslations } from "modules/i18n/hooks/useTranslations"
import { useState, useCallback } from "react"
import { NotificationProps } from "common/components/NotificationBar/types"

export const NotificationBar = ({ type, title, message, open }: NotificationProps) => {
  const t = useTranslations("components.notification_bar")

  const [visible, setVisible] = useState(open ?? true)
  const onClose = useCallback(() => setVisible(false), [setVisible])

  if (!visible) {
    return null
  }

  return (
    <section
      role="status"
      className={clsx("flex w-full flex-row items-center", {
        "text-green-700 bg-green-50": type === "success",
        "text-yellow-700 bg-yellow-50": type === "warning",
        "text-red-600 bg-red-50": type === "error",
        "text-gray-600 bg-gray-50": type === "info",
      })}
    >
      <div
        className={clsx("grow border-l-4 py-2 pl-3 pr-2", {
          "border-green-700": type === "success",
          "border-yellow-600": type === "warning",
          "border-red-600": type === "error",
          "border-gray-700": type === "info",
        })}
      >
        <div className="flex flex-row items-center gap-2">
          {type === "error" && <NoSymbolIcon className="size-5" />}
          {type === "warning" && <ExclamationCircleIcon className="size-5" />}
          {type === "success" && <CheckCircleIcon className="size-5" />}
          {type === "info" && <InformationCircleIcon className="size-5" />}

          <div className="grow text-base font-bold">{title}</div>

          <button className="cursor-pointer" onClick={onClose} aria-label={t("close")}>
            <XMarkIcon className="size-5 font-bold" />
          </button>
        </div>

        {message && <p className="pl-7 text-xs">{message}</p>}
      </div>
    </section>
  )
}
