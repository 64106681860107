import Script from "next/script"
import getConfig from "next/config"
import { useRegionConfig } from "modules/i18n/hooks/useRegionConfig"
import { RegionConfiguration } from "modules/i18n/utils/loadRegionConfiguration"

const DIDOMI_EMBED_ID = "a1179046-1792-42d9-812f-3daba6c97289"

// Didomi vendor SDK IDs used to identify vendor scripts.
// They can be found on Didomi's dashboard > "Consent Notices" > editing a consent > "Vendor & Purposes" list > "IAB/SDK ID" column
// @see https://developers.didomi.io/cmp/web-sdk/third-parties/custom-integrations/no-tag-manager#consent-to-vendors

// The Conversation
export const THE_CONVERSATION_SDK_ID = "c:theconver-dUNGGyiP"
export const THE_CONVERSATION_PROMOS_PURPOSE = "theconver-AA8pWL4T"

/**
 * We are going to wait 4 seconds until Didomi is ready to be used, otherwise, we can assume it is taking too
 * long to be loaded.
 * Didomi JS is loaded through the https://sdk.privacy-center.org/, which uses Amazon CloudFront CDN.
 * At the moment of this writing, its base JS to use Didomi's JS API transferred gziped size is 3.6 KB, which
 * should take less than a second even on a slow connection.
 */
export const DIDOMI_READY_TIMEOUT_IN_MILLISECONDS = 4000

// Didomi's API interface (https://developers.didomi.io/cmp/web-sdk/reference/api)
interface DidomiAPI {
  getUserConsentStatus: (vendorId: string, purpose: string) => boolean
}

/**
 * Check if Didomi is enabled on the environment
 */
export const isEnvEnabled = (): boolean => getConfig().publicRuntimeConfig.DIDOMI_ENABLED == "true"

/**
 * Check if Didomi is enabled for the region configuration
 */
export const isRegionEnabled = (regionConfiguration: RegionConfiguration): boolean => {
  return Boolean(regionConfiguration?.didomi?.enabled)
}

/**
 * Returns Didomi's custom `<script>` tag type `didomi/javascript` attribute if it's enabled, otherwise, it'll return
 * `text/javascript`
 */
export const scriptType = (regionConfiguration: RegionConfiguration): string =>
  isEnvEnabled() && isRegionEnabled(regionConfiguration) ? "didomi/javascript" : "text/javascript"

/**
 * Check if the current user has given consent for a specific purpose and vendor.
 *
 * @param regionConfiguration The region configuration
 * @param vendorId The vendor ID to check. E.g.: `c:theconver-dUNGGyiP`
 * @param purpose The purpose to check. E.g.: `theconver-AA8pWL4T`
 * @return {Promise<boolean>}
 * @fullfill {boolean} true if the user has given consent, false otherwise
 * @reject {Error} If the Didomi SDK is not ready after a timeout.
 */
export async function consentStatus({
  regionConfiguration,
  vendorId,
  purpose,
}: {
  regionConfiguration: RegionConfiguration
  vendorId: string
  purpose: string
}): Promise<boolean> {
  return new Promise((resolve, reject) => {
    // If Didomi is disabled, we're considering that users have given consent
    if (!isEnvEnabled() || !isRegionEnabled(regionConfiguration)) {
      resolve(true)
    }

    /**
     * The `window.didomiOnReady` is an array that will be populated with a function when Didomi JS is
     * initialized. We resolve its `Didomi` when its ready, or assume that users rejected it if Didomi has timed out.
     */
    setTimeout(() => {
      resolve(false)
    }, DIDOMI_READY_TIMEOUT_IN_MILLISECONDS)

    const didomiOnReady = window.didomiOnReady || []
    didomiOnReady.push((Didomi: DidomiAPI) => {
      const userConsentWithVendorAndItsPurpose = Didomi.getUserConsentStatus(purpose, vendorId)

      resolve(userConsentWithVendorAndItsPurpose)
    })
  })
}

/**
 * Didomi setup component. It exposes the `window.didomiOnReady` global object so we can we can safely invoke it
 * even if the environment does not have Didomi enabled.
 */
export const Didomi = () => {
  const regionConfig = useRegionConfig()

  if (!isEnvEnabled() || !isRegionEnabled(regionConfig)) {
    return (
      <Script
        data-testid="didomi-snippet"
        id="didomi-snippet"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.didomiOnReady = window.didomiOnReady || [];
          `,
        }}
      />
    )
  }

  return (
    <Script
      data-testid="didomi-snippet"
      id="didomi-snippet"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
          window.didomiOnReady = window.didomiOnReady || [];

          // Didomi's consent changed event
          window.didomiEventListeners = window.didomiEventListeners || [];
          window.didomiEventListeners.push({
            event: 'consent.changed',
            listener: function () {
              window.dispatchEvent(new CustomEvent('didomi:consent.changed'))
            }
          });

          window.gdprAppliesGlobally=true;(function(){function a(e){if(!window.frames[e]){if(document.body&&document.body.firstChild){var t=document.body;var n=document.createElement("iframe");n.style.display="none";n.name=e;n.title=e;t.insertBefore(n,t.firstChild)}
  else{setTimeout(function(){a(e)},5)}}}function e(n,r,o,c,s){function e(e,t,n,a){if(typeof n!=="function"){return}if(!window[r]){window[r]=[]}var i=false;if(s){i=s(e,t,n)}if(!i){window[r].push({command:e,parameter:t,callback:n,version:a})}}e.stub=true;function t(a){if(!window[n]||window[n].stub!==true){return}if(!a.data){return}
  var i=typeof a.data==="string";var e;try{e=i?JSON.parse(a.data):a.data}catch(t){return}if(e[o]){var r=e[o];window[n](r.command,r.parameter,function(e,t){var n={};n[c]={returnValue:e,success:t,callId:r.callId};a.source.postMessage(i?JSON.stringify(n):n,"*")},r.version)}}
  if(typeof window[n]!=="function"){window[n]=e;if(window.addEventListener){window.addEventListener("message",t,false)}else{window.attachEvent("onmessage",t)}}}e("__tcfapi","__tcfapiBuffer","__tcfapiCall","__tcfapiReturn");a("__tcfapiLocator");(function(e){
    var t=document.createElement("script");t.id="spcloader";t.type="text/javascript";t.async=true;t.src="https://sdk.privacy-center.org/"+e+"/loader.js?target="+document.location.hostname;t.charset="utf-8";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n)})("${DIDOMI_EMBED_ID}")})();
        `,
      }}
    />
  )
}
