import { useEffect } from "react"

export const useTimeoutEffect = (callback: () => unknown, timeout?: number) => {
  useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined
    if (timeout) {
      timeoutId = setTimeout(callback, timeout)
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [callback, timeout])
}
