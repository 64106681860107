import getConfig from "next/config"
import Head from "next/head"

/**
 * Check if Echobox is enabled on the environment
 */
export const isEnvEnabled = (): boolean => getConfig().publicRuntimeConfig.ECHOBOX_ENABLED == "true"

/**
 * Echobox setup component, where we import the Echobox Web Tag JS using Next.js' `Script` component.
 */
export const Echobox = () => {
  if (!isEnvEnabled()) {
    return null
  }

  return (
    <Head>
      <script async id="ebx" src="//applets.ebxcdn.com/ebx.js" key="echobox-snippet"></script>
    </Head>
  )
}
