import { CAN_USE_DOM } from "./utils"
import { nullTracer } from "./nullTracer"
import type { SimpleTracer, Span } from "./tracer"

export class FetchGraphqlQueryError extends Error {
  constructor(message?: string) {
    super(message)
    this.name = "FetchGraphqlQueryError"
  }
}

// Server side fetch calls require the absolute URLs.
export const GRAPHQL_ENDPOINT = CAN_USE_DOM
  ? "/api/graphql"
  : `${process.env.TC_ORIGIN}/api/graphql`

export const fetchGraphqlQuery = async <T>(
  query: string,
  headers?: Record<string, string>,
  variables?: Record<string, any>,
  tracer: SimpleTracer = nullTracer,
): Promise<T> => {
  const options = {
    headers: {
      "Content-Type": "application/json",
      "User-Agent": "tc/frontend",
      ...headers,
    },
    method: "POST",
  }

  const response = await tracer.trace("graphql.query", async (span?: Span) => {
    span?.addTags({
      query,
      variables,
    })

    return fetch(GRAPHQL_ENDPOINT, {
      body: JSON.stringify({ query, variables }),
      ...options,
    })
  })

  if (!response.ok) {
    throw new FetchGraphqlQueryError(`Status: ${response.status}`)
  }

  const { data, error } = await response.json()

  if (error) {
    throw new FetchGraphqlQueryError(error.message)
  }

  if (!data) {
    throw new FetchGraphqlQueryError("No data returned.")
  }

  return data
}
