import Head from "next/head"
import { useRegionCode } from "modules/i18n/hooks/useRegionCode"

interface MetaAttributesProps {
  title?: string
  description?: string
  ogTitle?: string
  ogDescription?: string
  siteName?: string
  type?: string
  image?: string
  updatedTime?: string
  url?: string
  articlePublisher?: string
}

/**
 * Component that will render meta tags on a page `<head>`. We are using the `key` attributes with the same
 * value as meta properties to avoid duplicate tags (source: https://nextjs.org/docs/api-reference/next/head)
 */
export const MetaAttributes = ({
  title,
  description,
  ogTitle,
  ogDescription,
  siteName,
  articlePublisher,
  updatedTime,
  url,
  type,
  image,
}: MetaAttributesProps) => {
  const regionCode = useRegionCode()

  return (
    <Head>
      {title && <title key="title">{title}</title>}

      <meta name="current-region" content={regionCode} />

      {description && <meta name="description" key="description" content={description} />}

      {ogTitle && <meta property="og:title" key="og:title" content={ogTitle} />}

      {siteName && <meta property="og:site_name" key="og:site_name" content={siteName} />}

      {ogDescription && (
        <meta property="og:description" key="og:description" content={ogDescription} />
      )}

      {type && <meta property="og:type" key="og:type" content={type} />}

      {image && <meta property="og:image" key="og:image" content={image} />}

      {updatedTime && (
        <meta property="og:updated_time" key="og:updated_time" content={updatedTime} />
      )}

      {url && <meta property="og:url" key="og:url" content={url} />}

      {articlePublisher && (
        <meta property="article:publisher" key="article:publisher" content={articlePublisher} />
      )}
    </Head>
  )
}
