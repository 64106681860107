import type { SimpleTracer, Span } from "./tracer"

// Simple tracer that performs the function without tracing
const nullTracer: SimpleTracer = {
  trace(name, fn) {
    return fn()
  },
}

export { nullTracer }
