// Since CSRF tokens are immutable entities, we will use `swr/immutable`
import useSWRImmutable from "swr/immutable"

export const CSRF_REQUEST_PATH = "/csrf-token"

export interface CSRF {
  param: string
  token: string
  error: any
}

/**
 * A hook that fetches Rails CSRF tokens.
 */
export const useCsrfToken = (): CSRF => {
  const { data, error } = useSWRImmutable<CSRF>(CSRF_REQUEST_PATH, async (url: string) => {
    const response = await fetch(url)

    if (!response.ok) {
      return Promise.reject(
        new Error(`An error occurred while fetching the CSRF token: (${response.status})`),
      )
    }

    return await response.json()
  })

  return {
    param: data?.param || "",
    token: data?.token || "",
    error,
  }
}
