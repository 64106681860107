import type { PropsWithChildren } from "react"
import clsx from "classnames"

export type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6 | 7

export interface HeadingProps {
  level?: HeadingLevel
  weight?: "regular" | "weak"
}

export const headingLevelClassNames = (level: number) => {
  return clsx("font-sans-heading", {
    "text-3xl lg:text-4xl xl:text-5xl": level === 1,
    "text-2xl leading-7 lg:text-3xl xl:text-4xl": level === 2,
    "text-xl leading-6 lg:text-2xl xl:text-3xl": level === 3,
    "text-lg leading-6 lg:text-xl": level === 4,
    "text-base leading-5 lg:text-lg lg:leading-6": level === 5,
    "text-sm": level === 6,
    "text-2xs": level === 7,
  })
}

export const Heading = ({
  level = 1,
  weight = "regular",
  children,
}: PropsWithChildren<HeadingProps>) => {
  const classNames = clsx(
    {
      "font-bold": weight === "regular",
      "font-medium": weight === "weak",
    },
    headingLevelClassNames(level),
  )

  switch (level) {
    case 7:
    case 6:
      return <h6 className={classNames}>{children}</h6>
    case 5:
      return <h5 className={classNames}>{children}</h5>
    case 4:
      return <h4 className={classNames}>{children}</h4>
    case 3:
      return <h3 className={classNames}>{children}</h3>
    case 2:
      return <h2 className={classNames}>{children}</h2>
    case 1:
    default:
      return <h1 className={classNames}>{children}</h1>
  }
}
